<template>
    <div v-if="dataSent">
        <p class="text-muted mt-3 text-center">
            An email has been sent. Click on the link included to set your
            password. You will also need to wait until an admin approves your
            account.
        </p>
        <router-link
            to="/login"
            class="btn btn-block btn-primary waves-effect waves-light mt-3"
        >
            Back to login
        </router-link>
    </div>

    <div v-else>
        <h1 class="mb-4 mt-3 text-center">
            Sign Up
        </h1>

        <b-form @submit.prevent="tryToSignUp">
            <b-form-group label="* First name" label-for="firstName">
                <b-form-input
                    id="firstName"
                    v-model="firstName"
                    required
                    placeholder="Enter first name"
                    :class="{
                        'is-invalid': hasError('firstName')
                    }"
                />
                <div class="invalid-feedback">
                    {{ getError('firstName') }}
                </div>
            </b-form-group>

            <b-form-group label="* Last name" label-for="lastName">
                <b-form-input
                    id="lastName"
                    v-model="lastName"
                    required
                    placeholder="Enter last name"
                    :class="{
                        'is-invalid': hasError('lastName')
                    }"
                />
                <div class="invalid-feedback">
                    {{ getError('lastName') }}
                </div>
            </b-form-group>

            <b-form-group label="* Email" label-for="email">
                <b-form-input
                    id="email"
                    v-model="email"
                    type="email"
                    required
                    placeholder="Enter email"
                    :class="{
                        'is-invalid': hasError('email')
                    }"
                />
                <div class="invalid-feedback">
                    {{ getError('email') }}
                </div>
            </b-form-group>

            <b-form-group label="Company name" label-for="companyName">
                <b-form-input
                    id="companyName"
                    v-model="companyName"
                    placeholder="Enter company name"
                />
            </b-form-group>

            <p class="mt-2">
                * Required fields
            </p>

            <b-form-group class="mt-4">
                <b-button type="submit" class="btn-block">
                    Sign Up
                </b-button>
            </b-form-group>

            <p class="text-center mt-2">
                Already have account?
                <router-link to="/login">
                    Log In
                </router-link>
            </p>
        </b-form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import { StatusCodes as HTTP } from 'http-status-codes';
import { required, email, minLength } from 'vuelidate/lib/validators';

export default {
    page: {
        title: 'Sign Up'
    },

    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            dataSent: false,
            serverErrors: []
        };
    },

    validations: {
        firstName: {
            required,
            minLength: minLength(2)
        },
        lastName: {
            required,
            minLength: minLength(2)
        },
        email: {
            required,
            email
        }
    },

    methods: {
        ...mapActions({
            signUp: 'auth/signUp'
        }),

        hasError(key) {
            return (
                this.$v[key].$error ||
                this.serverErrors.find(error => error.param === key)
            );
        },

        getError(key) {
            return this.$getValidationErrorMessageHandler.handle(
                this.$v,
                this.serverErrors,
                key
            );
        },

        async tryToSignUp() {
            try {
                this.serverErrors = [];

                this.$v.$touch();

                if (this.$v.$invalid) {
                    return;
                }

                await this.signUp({
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    companyName: this.companyName
                });

                this.dataSent = true;
            } catch (error) {
                const { response } = error;

                if (response) {
                    const { data, status } = response;

                    if (status === HTTP.BAD_REQUEST && data.errors) {
                        this.serverErrors = data.errors;

                        this.$toasterError('Recheck your form.');

                        return;
                    }
                }

                console.error(error);

                Sentry.captureException(error);

                this.$toasterError();
            }
        }
    }
};
</script>
